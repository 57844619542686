import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

const slider = '.js-hero-slider';

// Swiper init
const heroSlider = new Swiper(slider, {
    modules: [Pagination, Autoplay, EffectFade],
    effect: 'fade',
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.js-hero-slider-pagination',
        clickable: true,
        bulletClass: 'hero-slider__pagination-item',
        bulletActiveClass: 'is-active',
    },
});
